//import React, { useState } from "react";
import axios from 'axios';


export const addAdminLogin = async (client_id, user_name) => {
  const result = await axios(
    'https://api.ipify.org/?format=json',
  );
 
 let tmpIP = eval(JSON.stringify(result.data, null, 2).replace(/"([^"]+)":/g, '$1:'));
  console.log("tmpIP"+tmpIP);
  console.log(client_id);
  let newDate = new Date()
  const post ={
    client_id: client_id.toString(),
    date_time: newDate, 
    user_name: user_name, 
    ip_address : tmpIP 
  }
  try {
    const res = await axios.post('https://data.mongodb-api.com/app/fsml-eeveh/endpoint/logAdminLogin', post)
    console.log(res.data);
  } catch (e) {
    alert(e)
  }
}

export const addProductPageOpen = async (client_id) => {
  const result = await axios(
    'https://api.ipify.org/?format=json',
  );
 
 let tmpIP = eval(JSON.stringify(result.data, null, 2).replace(/"([^"]+)":/g, '$1:'));
  console.log("tmpIP"+tmpIP);
  console.log(client_id);
  let newDate = new Date()
  const post ={
    client_id: client_id.toString(),
    date_time: newDate, 
    client_info : {
      ip_address : tmpIP
    } 
  }
  try {
    const res = await axios.post('https://data.mongodb-api.com/app/fsml-eeveh/endpoint/openProductPage', post)
    console.log(res.data);
  } catch (e) {
    alert(e)
  }
}

export const addCommunication = async (client_id, method) => {
  console.log("pre get ip");
  const result = await axios(
    'https://api.ipify.org/?format=json',
  );
 
 let tmpIP = eval(JSON.stringify(result.data, null, 2).replace(/"([^"]+)":/g, '$1:'));
 // console.log("tmpIP"+tmpIP);
 // console.log(client_id);
  let newDate = new Date()
  const post ={
    method : method,
    client_id: client_id,
    date_time: newDate, 
    client_info : {
      ip_address : tmpIP
    } 
  }
  try {
    const res = await axios.post('https://data.mongodb-api.com/app/fsml-eeveh/endpoint/addCommunication', post)
    console.log(res.data);
  } catch (e) {
    alert(e)
  }
  console.log("post-ed ");
}

export const FetchDataInactive = async (clientId) => {
  let  client_id = clientId; 
  const result = await axios(
    'https://data.mongodb-api.com/app/fsml-eeveh/endpoint/inactiveVehicles?arg1=' + client_id,
  );
  return result; 
};

export const FetchDataActive = async (clientId) => {
  //console.log("clientId:"+clientId);
  let  client_id = clientId; 
  const result = await axios(
    'https://data.mongodb-api.com/app/fsml-eeveh/endpoint/vehicles?arg1=' + client_id,
  );
  return result; 
};
export const FetchMakerOptions = async (clientId) => {
 // console.log("clientId:"+clientId);
  let  client_id = clientId;  
  const result = await axios(
    'https://data.mongodb-api.com/app/fsml-eeveh/endpoint/makes?arg1=' + client_id,
  );
 // console.log("makerOptions client_id data: "+ client_id + result.data);
  return result; 
};
export const FetchModelOptions = async (clientId) => {
   //console.log("clientId :"+ clientId );
   let  client_id = clientId;  
   const result = await axios(
     'https://data.mongodb-api.com/app/fsml-eeveh/endpoint/models?arg1=' + client_id,
   );
  // console.log("makerOptions client_id data: "+ client_id + result.data);
   return result; 
 }; 
 export const FetchMakeModelOptions = async (clientId, make) => {
  //console.log("clientId :"+ clientId );
  if(make ===""){
    make = "Fiat" 
  }
  let  client_id = clientId;  
  const result = await axios(
    'https://data.mongodb-api.com/app/fsml-eeveh/endpoint/makemodels?arg1=' + client_id+"&arg2="+make,
  );
 // console.log("makerOptions client_id data: "+ client_id + result.data);
  return result; 
};
 export const FetchChassisOptions = async (clientId) => {
 // console.log("clientId :"+ clientId );
  let  client_id = clientId;  
  const result = await axios(
    'https://data.mongodb-api.com/app/fsml-eeveh/endpoint/chassis?arg1=' + client_id,
  );
 // console.log("chassis client_id data: "+ client_id + result.data);
  return result; 
};
export const FetchColorOptions = async (clientId) => {
  console.log("clientId :"+ clientId );
  let  client_id = clientId;  
  const result = await axios(
    'https://data.mongodb-api.com/app/fsml-eeveh/endpoint/colors?arg1=' + client_id,
  );
 // console.log("color client_id data: "+ client_id + result.data);
  return result; 
};

