import React, { useState, useEffect } from "react";
import axios from 'axios';
import "./bootstrap/dist/css/bootstrap.min.css"
//import Auth from "./Components/Auth"
import './App.css';
import './styles.css'
import { FetchMakerOptions } from "./Components/DB_DATA";
import { FetchModelOptions } from "./Components/DB_DATA";
import { FetchColorOptions } from "./Components/DB_DATA";
import { FetchChassisOptions } from "./Components/DB_DATA";
import { addAdminLogin } from "./Components/DB_DATA";
import AdminFooter from './Components/AdminFooter';
import InactiveCars from "./Pages/InactiveCars";
import Product from "./Pages/Product";
import Home from "./Pages/Home";
import ModalQ from 'react-modal';
import ModalR from 'react-modal';
import ModalS from 'react-modal';
import ModalT from 'react-modal';
// import Settings from "./Pages/Settings";
// import Logout from "./Pages/Logout";
import { Route, Routes } from 'react-router-dom';
import Users from "./Pages/Users";
//import CISLogo from './CISLogo.png';
import ReactDOM from 'react-dom/client';
import ClientLogo_100 from './logo_100.png';
import ClientLogo_101 from './logo_101.png';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
export const UserContext = React.createContext();

function App() {
  let nameValue = '';
  let client_id = ''; /* ??? initialization which will be populated later */
  let client_name = '';
  const OtoFab_URL = 'https://otofab.com/urun';
 // Add each new client here:
  var logoInUse = ClientLogo_100;
  if(client_id === "101"){
    logoInUse = ClientLogo_101;
  }
  
  const [open, setopen] = useState(true);
  const toggleOpen = () => {
    setopen(!open)
  }
  const [LoginStatus, setLoginStatus] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState("");

  const customFieldUpdateStyles = {
    content: {
      top: '20%',
      left: '20%',
      right: '20%',
      bottom: '20%',
      marginLeft: '10%',
      transform: 'translate(-20%, -20%)',
      padding: '10px',
    },
  };
  // console.log("initial LoginStatus:" + LoginStatus);
  /******************** */
  function User() {
    return (
      <UserContext.Consumer>
        {value => <h6>{value}</h6>}
        {/* prints: stored value */}
      </UserContext.Consumer>
    )
  }
  /********************** */
  function Navbar(props) {
    let loggedIn = props.LoginStatus;
    let userName = props.currentUser;
    let corpName = '';
    corpName = props.corpname;
   /* console.log(" loggedIn:" + loggedIn);
    console.log(" userName:" + userName);
    console.log(" corpName:" + corpName);
    console.log(" client_id:" + client_id); */
    switch (corpName) {
      case 'ornek':
        client_id = "100";
        client_name = "OtoFab LTD.";
        break;
      case 'fsml':
        client_id = "101";
        client_name = "Fırat Serhat Motors LTD.";
        break;
      default:
        break;
    }
    console.log(" client_name:" + client_name);
    console.log(" ...end ");
    let savedClientName = '';
    {
      useState(() => {
        // retriving input name
        const savedName = localStorage.getItem("currentUser");
        savedClientName = JSON.parse(localStorage.getItem("client_name"));
        nameValue = JSON.parse(savedName);
      })
    }
    if (userName !== "") {
      loggedIn = true;
    }
    if (nameValue !== "") {
      loggedIn = true;
    }

    return <nav className="nav">
      <div>
       { /* <div className="col5">
          <a href={OtoFab_URL} target="_blank" rel="noreferrer">
            <img src={OtoFabLogo_Tiny} alt="example" className="rounded-corners" />
          </a>
        </div> */ }
      <div className="row">
          <h5><a href={OtoFab_URL}><strong>&nbsp;&nbsp;OtoFab Galeri Web Sitesi Yönetim Sistemi</strong></a></h5>
          </div> 
      
      </div>
      <div className="row">
          <h5 style={{ textAlign:"left", color: "black" }}>{savedClientName}</h5>
          </div>
      {/* {currentUser ?  greetingValue :  nameValue ? greetingValue : ""} {currentUser ?  currentUser : nameValue} */}
      <ul> 
  
          {loggedIn ?
        <li>
          <a href="/aktif">Araçlar</a>
        </li>
        :
        <li>
          <a href="/"></a>
        </li>
      }
        {loggedIn ?
          <li>
            <a href="/deaktif">Deaktif Araçlar</a>
          </li>
          :
          <li>
            <a href="/"></a>
          </li>
        }
        {loggedIn ?
          <li>
            <a href="/kullanici">Kullanıcılar</a>
          </li> :
          <li>
            <a href="/"></a>
          </li>
        }
        {loggedIn ?
          <li>
            <a href="/abonelik">Abonelik</a>
          </li> :
          <li>
            <a href="/"></a>
          </li>
        }
        {loggedIn ?
          <li>
            <a href="/ayarlar">Ayarlar</a>
          </li> :
          <li>
            <a href="/"></a>
          </li>
        }
        {loggedIn ?
          <li>
            <a href="/bitti">Çıkış</a>
          </li>
          :
          <li>
            <a href="/"></a>
          </li>
        }
      </ul>
    </nav>
  }
  // ****************************
  function Subscriptions() {
    let savedId = localStorage.getItem("client_id");
    savedId = JSON.parse(savedId);
    let savedClientName = JSON.parse(localStorage.getItem("client_name"));
    return (
      <div style={{ backgroundColor: "lightgray", color: "black" }}>
        <img src={logoInUse}  title="Ornek Motors" alt="example" style={{ float: "left" }} className="rounded-corners" />
        <div><h5 style={{ float: "center" }}>&nbsp;&nbsp;Abonelik Bilgileri</h5></div>
        <br></br>
        <div className="container">
          <div className="row mt-6">
            <table style={{ "textAlign": "left", "borderWidth": "4px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
              <thead style={{ 'backgroundColor': 'beige', 'borderColor': "#aaaaaa", 'width': '300px' }}>
                <tr>
                  <th>Kurum Adı</th>
                  <th>Kurum Kodu</th>
                  <th>Hizmet</th>
                </tr>
              </thead>
              <tbody>

                <tr style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  <td style={{ width: 200 }}>{savedClientName}</td>
                  <td style={{ width: 150 }}>{savedId}</td>
                  <td style={{ width: 240 }}>Standard Paket</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <AdminFooter />
      </div>
    )
  }
  // ********************************
  function Settings() {
    const [QmodalIsOpen, QsetIsOpen] = React.useState(false);
    const [RmodalIsOpen, RsetIsOpen] = React.useState(false);
    const [SmodalIsOpen, SsetIsOpen] = React.useState(false);
    const [TmodalIsOpen, TsetIsOpen] = React.useState(false);
    const [newMake, setNewMake] = React.useState('');
    const [newModel, setNewModel] = React.useState('');
    const [selectedMake, setSelectedMake] = React.useState('');
    const [newColor, setNewColor] = React.useState('');
    const [newChassis, setNewChassis] = React.useState('');

    ModalQ.setAppElement(document.getElementById('root'));
    ModalR.setAppElement(document.getElementById('root'));
    ModalS.setAppElement(document.getElementById('root'));
    ModalT.setAppElement(document.getElementById('root'));

    let savedId = localStorage.getItem("client_id");
    savedId = JSON.parse(savedId);
    let savedIdStr = savedId.toString();
    // savedId = JSON.parse(savedId);
    // console.log("savedIdStr:" + savedIdStr);
    const [makeOptionsx, setMakeOptions] = React.useState([]);
    // const [makeModelOptionsx, setMakeModelOptions] = React.useState([]);
    const [newModelsDatax, setDBModelsData] = React.useState([]);
    const [colorOptionsx, setColorOptions] = React.useState([]);
    const [chassisOptionsx, setChassisOptions] = React.useState([]);
    //const [dispMakeModelList, setDispMakeModelList] = React.useState([]);

    React.useEffect(() => {
      FetchMakerOptions(savedId).then(res => {
        setMakeOptions(eval(JSON.stringify(res.data, null, 2).replace(/"([^"]+)":/g, '$1:')))
      })
    }, []);

    React.useEffect(() => {
      FetchModelOptions(savedId).then(res => {
        setDBModelsData(eval(JSON.stringify(res.data, null, 2).replace(/"([^"]+)":/g, '$1:')))
      })
    }, []);

    React.useEffect(() => {
      FetchColorOptions(savedId).then(res => {
        setColorOptions(eval(JSON.stringify(res.data, null, 2).replace(/"([^"]+)":/g, '$1:')))
      })
    }, []);

    React.useEffect(() => {
      FetchChassisOptions(savedId).then(res => {
        setChassisOptions(eval(JSON.stringify(res.data, null, 2).replace(/"([^"]+)":/g, '$1:')))
      })
    }, []);


    const addMake = async (e) => {
      e.preventDefault();
      const post = {
        client_id: savedIdStr,
        value: newMake,
        label: newMake,
        is_active: "Yes"
      }
      console.log("newMake" + newMake);
      console.log(post);
      try {
        console.log("try 1");
        const res =  axios.post('https://data.mongodb-api.com/app/fsml-eeveh/endpoint/addMake', post);
      } catch (e) {
        alert(e);
        console.log("try 2");
      }
      console.log("try 3");
      const postMakeModel = {
        client_id: savedIdStr, //make also needed as arguments to the Post URL
        make: newMake,
        modelList: [{value:"----", label:"----"}]
      }
      try {
        console.log("try 31");
        const res = await axios.post('https://data.mongodb-api.com/app/fsml-eeveh/endpoint/addInitialMakeModel', postMakeModel);
      } catch (e) {
        alert(e);
        console.log("try 32");
      }
      console.log("init make model:"+postMakeModel);
      QcloseModal();
    }
    const addModel = async (e) => {
      e.preventDefault();
      const post = {
        client_id: savedIdStr, //make also needed as arguments to the Post URL
        value: newModel,
        label: newModel,
      }
      console.log("newModel" + newModel);
      console.log(post);
      try {
        console.log("try 1");
        const res = await axios.post('https://data.mongodb-api.com/app/fsml-eeveh/endpoint/addMakeModel?arg1=' + savedIdStr + '&arg2=' + selectedMake, post);
      } catch (e) {
        alert(e);
        console.log("try 2");
      }
      console.log("try 3");
      RcloseModal();
    }

    const addChassis = async (e) => {
      e.preventDefault();
      const post = {
        client_id: savedIdStr,
        value: newChassis,
        label: newChassis,
        is_active: "Yes"
      }
      console.log(post);
      try {

        const res = await axios.post('https://data.mongodb-api.com/app/fsml-eeveh/endpoint/addChassis', post);
      } catch (e) {
        alert(e);
      }
      ScloseModal();
    }

    const addColor = async (e) => {
      e.preventDefault();
      const post = {
        client_id: savedIdStr,
        value: newColor,
        label: newColor,
        is_active: "Yes"
      }
      console.log(post);
      try {
        const res = await axios.post('https://data.mongodb-api.com/app/fsml-eeveh/endpoint/addColor', post);
      } catch (e) {
        alert(e);
      }
      TcloseModal();
    }

    function getIndex(value, arr, prop) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i][prop] === value) {
          return i;
        }
      }
      return 0; //to handle the case where the value doesn't exist
    }
    function QopenModal() {
      QsetIsOpen(true);
    }
    function QafterOpenModal() {
      QsetIsOpen(true);
    }
    function QcloseModal() {
      QsetIsOpen(false);
    }
    function RopenModal() {
      RsetIsOpen(true);
    }
    function RafterOpenModal() {
      RsetIsOpen(true);
    }
    function RcloseModal() {
      RsetIsOpen(false);
    }
    function SopenModal() {
      SsetIsOpen(true);
    }
    function SafterOpenModal() {
      SsetIsOpen(true);
    }
    function ScloseModal() {
      SsetIsOpen(false);
    }
    function TopenModal() {
      TsetIsOpen(true);
    }
    function TafterOpenModal() {
      TsetIsOpen(true);
    }
    function TcloseModal() {
      TsetIsOpen(false);
    }
    function getMake() {
      const enteredMake = prompt('Yeni marka ismini giriniz');
      setNewMake(enteredMake);
      //console.log("enteredMake:" + enteredMake);
    }
    function getModel() {
      const enteredModel = prompt('Yeni model ismini giriniz');
      setNewModel(enteredModel);
      //console.log("enteredMake:" + enteredMake);
    }
    function getColor() {
      const enteredColor = prompt('Yeni renk ismini giriniz');
      setNewColor(enteredColor);
      // console.log("enteredColor:" + enteredColor);
    }

    function getChassis() {
      const enteredChassis = prompt('Yeni kasa çeşidini giriniz');
      setNewChassis(enteredChassis);
      // console.log("enteredChassis:" + enteredChassis);
    }

    function DispMakeList(props) {
      const text = props.text;
      let dispText = "";
      let dispList = [];
      dispText = "Mevcut Markalar";
      dispList = makeOptionsx.filter(item => item.label !== "----");

      return (
        <>
          <h5>Liste Güncelleme</h5>
          <p></p><br></br>
          <table style={{ "textAlign": "left", "borderWidth": "4px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
            <thead style={{ 'backgroundColor': 'beige', 'borderColor': "#aaaaaa", 'width': '300px' }}>
              <tr>
                <th style={{ width: 200 }}>{dispText}</th>
                <th style={{ width: 60 }}><button onClick={getMake}><AddCircleOutlinedIcon /></button></th>
                <br></br>
              </tr>
            </thead>
            <tbody>
              <tr style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                <td style={{ width: 150 }} ><p>{newMake}</p>{dispList.map(item => <p>{item.label}</p>)} </td>
              </tr>
            </tbody>
          </table>
        </>
      )
    }

    function DispModelList(props) {
      const text = props.text;
      let dispText = "";
      let dispMakeList = [];
      let dispMakeModelList = [];

      dispMakeList = makeOptionsx;
      //dispMakeModelList = makeModelOptionsx.filter(item => item.label !== "----");
      //setDispMakeModelList(makeModelOptionsx);

      return (
        <>
          <h5>Liste Güncelleme</h5>
          <p></p><br></br>
          <table style={{ "textAlign": "left", "borderWidth": "4px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
            <thead style={{ 'backgroundColor': 'beige', 'borderColor': "#aaaaaa", 'width': '300px' }}>
              <tr>
                <th style={{ width: 200 }}>
                  <label className="form-label" htmlFor="make" style={{ color: 'black' }}>
                    <b>Marka:&nbsp;</b>
                  </label>
                  <select name="make" options={dispMakeList} defaultValue={selectedMake} onChange={event => setSelectedMake(event.target.value)} required>
                    {dispMakeList.map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </th>
                <th style={{ width: 160 }}><button onClick={getModel}><AddCircleOutlinedIcon /></button></th>
                <br></br>
              </tr>
            </thead>
            <tbody>
              <tr><td>
                <b>Mevcut Modeller:&nbsp;</b>
                <p>{newModel}</p>{selectedMake ? newModelsDatax[getIndex(selectedMake, newModelsDatax, 'make')].modelList.filter(item => item.label !== "----").map(item => <p>{item.label}</p>) : ""}</td></tr>
            </tbody>
          </table>
        </>
      )
    }
    function DispChassisList(props) {
      const text = props.text;
      let dispText = "";
      let dispList = [];

      dispText = "Mevcut Kasa Tipleri";
      dispList = chassisOptionsx.filter(item => item.label !== "----");
      return (
        <>
          <h5>Liste Güncelleme</h5>
          <p></p><br></br>
          <table style={{ "textAlign": "left", "borderWidth": "4px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
            <thead style={{ 'backgroundColor': 'beige', 'borderColor': "#aaaaaa", 'width': '300px' }}>
              <tr>
                <th style={{ width: 250 }}>{dispText}</th>
                <th style={{ width: 60 }}><button onClick={getChassis}><AddCircleOutlinedIcon /></button></th>
                <br></br>
              </tr>
            </thead>
            <tbody>
              <tr style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                <td style={{ width: 150 }} ><p>{newChassis}</p>{dispList.map(item => <p>{item.label}</p>)} </td>
              </tr>
            </tbody>
          </table>
        </>
      )
    }
    function DispColorList(props) {
      const text = props.text;
      let dispText = "";
      let dispList = [];

      dispText = "Mevcut Renkler";
      dispList = colorOptionsx.filter(item => item.label !== "----");

      return (
        <>
          <h5>Liste Güncelleme</h5>
          <p></p><br></br>
          <table style={{ "textAlign": "left", "borderWidth": "4px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
            <thead style={{ 'backgroundColor': 'beige', 'borderColor': "#aaaaaa", 'width': '300px' }}>
              <tr>
                <th style={{ width: 200 }}>{dispText}</th>
                <th style={{ width: 60 }}><button onClick={getColor}><AddCircleOutlinedIcon /></button></th>
                <br></br>
              </tr>
            </thead>
            <tbody>
              <tr style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                <td style={{ width: 150 }} ><p>{newColor}</p>{dispList.map(item => <p>{item.label}</p>)} </td>
              </tr>
            </tbody>
          </table>
        </>
      )
    }
    return (
      <div style={{ backgroundColor: "lightgray", color: "black" }}>
        <img src={logoInUse} alt="example" style={{ float: "left" }} className="rounded-corners" />
        <div><h5 style={{ float: "center" }}>&nbsp;Ayarlar</h5></div>
        <br></br>
        <div className="card">
          <div className="item">
            <button onClick={QopenModal} style={{ color: 'blue', border: 'none', float: 'left', backgroundColor: '#ffe' }}>Markalar</button>
            <ModalQ
              isOpen={QmodalIsOpen}
              onAfterOpen={QafterOpenModal}
              onRequestClose={QcloseModal}
              style={customFieldUpdateStyles}
              contentLabel="Markalar">
              <form onSubmit={addMake}>
                <div>
                  <button variant="contained" style={{ float: 'right' }} className="float-right" onClick={QcloseModal}>X</button>
                  <div>
                    <DispMakeList text="make" />
                  </div>
                </div>
                <button type="submit" className="btn btn-danger" id="submit" style={{ float: "right", color: "white" }} value="Submit"
                ><b>Güncelle</b></button>
              </form>
            </ModalQ>

          </div>
          <div className="item">
            <button onClick={RopenModal} style={{ color: 'blue', border: 'none', float: 'left', backgroundColor: '#ffe' }}>Modeller</button>
            <ModalR
              isOpen={RmodalIsOpen}
              onAfterOpen={RafterOpenModal}
              onRequestClose={RcloseModal}
              style={customFieldUpdateStyles}
              contentLabel="Modeller">
              <form onSubmit={addModel}>
                <div>
                  <button variant="contained" style={{ float: 'right' }} className="float-right" onClick={RcloseModal}>X</button>
                  <div>
                    <DispModelList text="model" />
                  </div>
                </div>
                <button type="submit" className="btn btn-danger" id="submit" style={{ float: "right", color: "white" }} value="Submit"
                ><b>Güncelle</b></button>
              </form>
            </ModalR>
          </div>
          <div className="item">
            <button onClick={SopenModal} style={{ color: 'blue', border: 'None', float: 'left', backgroundColor: '#ffe' }}>Kasa Çeşitleri</button>
            <ModalS
              isOpen={SmodalIsOpen}
              onAfterOpen={SafterOpenModal}
              onRequestClose={ScloseModal}
              style={customFieldUpdateStyles}
              contentLabel="Kasa Çeşitleri">
              <form onSubmit={addChassis}>
                <div>
                  <button variant="contained" style={{ float: 'right' }} className="float-right" onClick={ScloseModal}>X</button>
                  <div>
                    <DispChassisList text="chassis" />
                  </div>
                </div>
                <button type="submit" className="btn btn-danger" id="submit" style={{ float: "right", color: "white" }} value="Submit"
                ><b>Güncelle</b></button>
              </form>
            </ModalS>
          </div>
          <div className="item">
            <button onClick={TopenModal} style={{ color: 'blue', border: 'none', float: 'left', backgroundColor: '#ffe' }}>Renk İsimleri</button>
            <ModalT
              isOpen={TmodalIsOpen}
              onAfterOpen={TafterOpenModal}
              onRequestClose={TcloseModal}
              style={customFieldUpdateStyles}
              //className="list-dialog"
              contentLabel="Renkler">
              <form onSubmit={addColor}>
                <div>
                  <button variant="contained" style={{ float: 'right' }} className="float-right" onClick={TcloseModal}>X</button>
                  <div>
                    <DispColorList text="color" />
                  </div>
                </div>
                <button type="submit" className="btn btn-danger" id="submit" style={{ float: "right", color: "white" }} value="Submit"
                ><b>Güncelle</b></button>
              </form>
            </ModalT>
          </div>
        </div>
        <div className="container">
          <div className="row mt-6">

          </div>
        </div>
        <AdminFooter />
      </div>
    )
  }
  // ********************************
  const Logout = (props) => {
    // const client_id = "101"; /* ??? initialization which is NOT used */
    setLoginStatus(false);
    useEffect(() => {
      // storing input name
      localStorage.setItem("currentUser", JSON.stringify(""));

    }, [currentUser]);
    useEffect(() => {
      localStorage.setItem("client_name", JSON.stringify(""));
    }, client_name);
    useEffect(() => {
      localStorage.setItem("client_id", JSON.stringify(""));
    }, client_id);
    window.location.replace('/');
    return (
      <Auth />
    )
  }


  // ******************
  function Auth() {
    //export default function ({ setLoginStatus }) {
    let [authMode, setAuthMode] = useState("signin");
    // console.log("Auth LoginStatus:" + LoginStatus);

    const checkAuth = async (e) => {
      e.preventDefault();
      const { usrname, pswd, corpname } = e.target.elements;
      /* console.log("usrname:" + usrname.value);
       console.log("pswd:" + pswd.value);
       console.log("corpName:" + corpname.value) */

      switch (corpname.value.toLowerCase()) {
        case 'ornek':
          client_id = "100";
          client_name = "OtoFab LTD.";
          break;
        case 'fsml':
          client_id = "101";
          client_name = "Fırat Serhat Motors LTD.";
          break;
        default:
          break;
      }

      let hidPassword = base64_encode(pswd.value);
      let URL = "https://data.mongodb-api.com/app/fsml-eeveh/endpoint/authenticateUser?arg1=" + client_id + "&arg2=" + usrname.value + "&arg3=" + hidPassword;

      try {
        const res = await axios(URL,);
        const root = ReactDOM.createRoot(document.getElementById('root'));
        const obj1 = JSON.stringify(res.data).replace("[", "").replace("]", "");
        const obj2 = JSON.parse(obj1);

        if (hidPassword === obj2.password) {

          setLoginStatus(true);
          setCurrentUser(obj2.username);
          // storing input name
          localStorage.setItem("client_id", JSON.stringify(client_id));
          localStorage.setItem("client_name", JSON.stringify(client_name));
          <Routes>
            <Route path="/" element={<><Navbar LoginStatus={LoginStatus} currentUser={currentUser} corpName={corpname.value} /><Home client_id={client_id} /><User /></>}></Route>
          </Routes>

          addAdminLogin(client_id,  usrname.value);
        }
        else {
          setLoginStatus(false);
          alert("Geçersiz kullanıcı adı veya şifre!");
          root.render((<><Navbar currentUser="" /><Auth /></>)); //LoginStatus={false} 
        }
      } catch (e) {
        alert('Geçersiz kullanıcı adı veya şifre!')
      }
    }

    const changeAuthMode = () => {
      setAuthMode(authMode === "signin" ? "signup" : "signin")
    }

    if (authMode === "signin") {
      return (
        <div className="Auth-form-container">
          <form className="Auth-form" onSubmit={checkAuth}>
            <div className="Auth-form-content">
              <h3 className="Auth-form-title">Sisteme Giriş</h3>
              <div className="form-group mt-3">
                <label>Kullanıcı Adı</label>
                <input
                  type="text"
                  className="form-control mt-1"
                  name="usrname"
                  id="usrname" required
                  placeholder="Kullanıcı Adı"
                />
              </div>
              <div className="form-group mt-3">
                <label>Kurum</label>
                <input
                  type="text"
                  className="form-control mt-1"
                  name="corpname"
                  id="corpname" required
                  placeholder="Kurum"
                />
              </div>
              <div className="form-group mt-3">
                <label>Şifre</label>
                <input
                  type="password"
                  className="form-control mt-1"
                  name="pswd" id="pswd" required
                  placeholder="Şifreyi Giriniz"
                />
              </div>
              <div className="d-grid gap-2 mt-3">
                <button type="submit" className="btn btn-primary">
                  Giriş Yap
                </button>
              </div>
              <p className="text-center mt-2" style={{fontSize:12}}>
                <a href="https://otofab.com/urun"> Daha fazla bilgi</a>
              </p>
            </div>
          </form>
        </div>
      )
    }
    return (
      <div className="Auth-form-container">
        <form className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Sign In</h3>
            <div className="text-center">
              Kayıtlı Kullanıcı?{" "}
              <span className="link-primary" onClick={changeAuthMode}>
                Giriş Yap
              </span>
            </div>
            <div className="form-group mt-3">
              <label>Kullanıcı Adı</label>
              <input
                type="email"
                className="form-control mt-1"
                placeholder="koordinator"
              />
            </div>
            <div className="form-group mt-3">
              <label>Kurum Adı</label>
              <input
                type="text"
                className="form-control mt-1"
                placeholder="FSML"
              />
            </div>
            <div className="form-group mt-3">
              <label>Şifre</label>
              <input
                type="password"
                className="form-control mt-1"
                placeholder="Password"
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="btn btn-primary">
                Giriş Yap
              </button>
            </div>
            <p className="text-center mt-2">
              Şifremi Unuttum <a href="#">password?</a>
            </p>
          </div>
        </form>
      </div>
    )
  }
  // *****************
  let component
  useEffect(() => {
    // storing input name
    if (currentUser !== "") {
      localStorage.setItem("currentUser", JSON.stringify(currentUser));
    }
  }, [currentUser]);

  return (
    <>
      {useState(() => {
        // retriving input name
        const savedName = localStorage.getItem("currentUser");
        nameValue = JSON.parse(savedName);
      })
      }

      <UserContext.Provider value={JSON.stringify(currentUser)}>
        <Navbar LoginStatus={LoginStatus} currentUser={currentUser ? currentUser : nameValue} />
        <div className="container">
          <Routes>
            <Route path="/" element={currentUser ? <Home client_id={client_id} /> : <Auth />} />
            <Route path="/aktif" element={currentUser ? <Home client_id={client_id} /> : nameValue ? <Home client_id={client_id} /> : <Auth />} />
            <Route exact path="/deaktif" element={currentUser ? <InactiveCars /> : nameValue ? <InactiveCars /> : <Auth />}></Route>
            <Route exact path="/abonelik" element={currentUser ? <Subscriptions /> : nameValue ? <Subscriptions /> : <Auth />}></Route>
            <Route exact path="/kullanici" element={currentUser ? <Users /> : nameValue ? <Users /> : <Auth />}></Route>
            <Route exact path="/ayarlar" element={currentUser ? <Settings /> : nameValue ? <Settings /> : <Auth />}></Route>
            <Route exact path="/bitti" element={currentUser ? <Logout /> : nameValue ? <Logout /> : <Auth />}></Route>
            <Route exact path="/urun" element={ <Product />}></Route>
          </Routes>
        </div>
      </UserContext.Provider>
    </>
  );
}
export default App;