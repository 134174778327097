
import React, { useState } from "react";
import "./VehicleItem.css";
import "./styles.css";
import Modal from 'react-modal';
import '../bootstrap/dist/css/bootstrap.min.css'
//import { modelsData } from "../Components/MOCK_DATA";
// import { xxmakerOptions } from "../Pages/Home";
import { FetchMakerOptions } from "../Components/DB_DATA";
import { FetchModelOptions } from "../Components/DB_DATA";
import { FetchChassisOptions } from "../Components/DB_DATA";
import { FetchColorOptions } from "../Components/DB_DATA";
import '../bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import Multiselect from 'multiselect-react-dropdown';
import uploadFile from "./upload";
import { yearOptions } from "../Pages/Home";
//import { chassisOptions } from "../Pages/Home";
import { fuel_typeOptions } from "../Pages/Home";
import { locationOptions } from "../Pages/Home";
import { transmissionOptions } from "../Pages/Home";
import { steering_wheelOptions } from "../Pages/Home";
import { colorOptions } from "../Pages/Home";
import { int_accessoriesOptions } from "../Pages/Home";
import { ext_accessoriesOptions } from "../Pages/Home";
import { ent_accessoriesOptions } from "../Pages/Home";
import { activeOptions } from "../Pages/Home";
// import {MDBInput, MDBCheckbox} from 'mdb-react-ui-kit';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
// import CheckboxListSecondary from './CheckboxListSecondary';

const VehicleItem = ({ item }) => {

  // const client_id = "101"; /* ??? initialization which is actually used */
  const client_id = item?.client_id;
// console.log("passed item client_id: "+client_id); all good here!
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [makerOptions, setDBMakerOptions] = React.useState([]);
  const [newModelsDatax, setDBModelsData] = React.useState([]);
  const [chassisOptionsx, setChassisOptions] = React.useState([]);
  const [colorOptionsx, setColorOptions] = React.useState([]);
  const [make, setMaker] = useState('');
  const [model, setModel] = useState('');
  const [image, setImage] = useState('');
  const [public_id, setPublic_id] = useState('');
  // const [picture_gallery_urls, setPicture_gallery_urls] = useState([]);
  let picture_gallery_urls = [];
  const [location, setLocation] = React.useState('');
  const [chassis, setChassis] = useState('');
  const [price, setPrice] = React.useState('~|~');
  const [year, setYear] = React.useState('');
  const [horse_power, setHorse_power] = React.useState('~|~');
  const [fuel_type, setFuel_type] = React.useState('');
  const [transmission, setTransmission] = React.useState('');
  const [steering_wheel, setSteering_wheel] = React.useState('');
  const [engine_volume, setEngine_volume] = React.useState('~|~');
  const [mileage, setMileage] = React.useState('~|~');
  const [sub_model, setSub_model] = React.useState('~|~');
  const [color, setColor] = React.useState('');
  const [is_active, setIs_active] = React.useState('');
  const [showDel, setShowDel] = React.useState(false);
  React.useEffect(() => {
    FetchMakerOptions(client_id).then(res => {
      setDBMakerOptions(eval(JSON.stringify(res.data, null, 2).replace(/"([^"]+)":/g, '$1:')))
    })
  }, []);
  React.useEffect(() => {
    FetchModelOptions(client_id).then(res => {
      setDBModelsData(eval(JSON.stringify(res.data, null, 2).replace(/"([^"]+)":/g, '$1:')))
    })
  }, []); 
  React.useEffect(() => {
    FetchChassisOptions(client_id).then(res => {
      setChassisOptions(eval(JSON.stringify(res.data, null, 2).replace(/"([^"]+)":/g, '$1:')))
    })
  }, []);
  React.useEffect(() => {
    FetchColorOptions(client_id).then(res => {
      setColorOptions(eval(JSON.stringify(res.data, null, 2).replace(/"([^"]+)":/g, '$1:')))
    })
  }, []);
  var newModelsData =[{make: "", modelList: [{value: "", label:""}]}];
  //console.log("newModelsDatax length: "+newModelsDatax.length);
  if( newModelsDatax.length !== 0){
    newModelsData = newModelsDatax;
  }

  // console.log("makerOptions length: "+makerOptions.length);
  let gChecked = [0];

  const delVehicle = async (e) => {
    e.preventDefault()
    //let status = false;
    const post = [];
    var result = window.confirm("Silmek istediğinizden emin misiniz?");
    if (result) {
      //Logic to delete the item
      console.log("item?.public_id:" + item?.public_id)
      try {
        // ??? not working! so I resorted to logging the event instead so I can use a utility to delete marked images later.
        const res = await axios.post('https://data.mongodb-api.com/app/fsml-eeveh/endpoint/deleteVehicle?arg1=' + item?.client_id + '&arg2=' + item?.vehicleId, post)
        // console.log(res.data);
        // log the delete request into a record in the DBData

        let currentDateTime = Date().toLocaleString();
        console.log(currentDateTime);
        console.log("public_id: " + public_id);
        logImageDelete(item?.public_id, currentDateTime);
        let i = 0;
        for (i = 0; i < item?.picture_gallery_urls.length; i++) {
          console.log("public_id: " + item?.picture_gallery_urls[i]?.public_id);
          logImageDelete(item?.picture_gallery_urls[i]?.public_id, currentDateTime);
        }
        window.location.reload(false);
      } catch (e) {
        alert(e)
      }
    }
    // return status;
  }
  function getIndex(value, arr, prop) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return 0; //to handle the case where the value doesn't exist
  }
  const customStyles = {
    content: {
      top: '15%',
      height: '60%',
      width: '60%',
      left: '15%',
      right: '15%',
      bottom: '15%',
      marginRight: '2%',
      transform: 'translate(-5%, -5%)',
      backgroundcolor: "gray",
    },
    alignItems: "center",
  };

  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const [fileUploaded, setFileUploaded] = useState(false);
  function CheckboxListSecondary({ urlList }) {

    const a = Array(...Array(urlList.length).keys());
    const [checked, setChecked] = React.useState([a.length]);
    // gChecked.length = a.length;
    const handleToggle = (value: number) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
      // console.log("urlList length:"+ a);
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    };
    gChecked = checked;
    return (

      <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {a.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value}`;
          return (

            <ListItem
              key={value}
              secondaryAction={
                <Checkbox
                  edge="end"
                  onChange={handleToggle(value)}
                  checked={checked.indexOf(value) !== -1}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    alt={`Avatar n°${value + 1}`}
                    src={urlList[value].url}
                    sx={{ width: 100, height: 80 }}
                    variant="square"
                  />
                </ListItemAvatar>
                <ListItemText id={labelId} primary={` `} /> {/* Line item ${value + 1} */}
              </ListItemButton>
            </ListItem>
          );

        })}


        {`gChecked: ${gChecked}`}
      </List>
    );
  }
  function logImageDelete(imgPublic_Id, date_deleted) {
    let postURL = "https://data.mongodb-api.com/app/fsml-eeveh/endpoint/logImageDelete";
    const logData = {
      client_id: client_id,
      date_deleted: date_deleted,
      public_id: imgPublic_Id
    }
    try {
      const res = axios.post(postURL, logData);
    }
    catch (error) {
      console.log(error.message);
    }
  }

  function UploadWidget({ imgPublic_Id }) {
    const [percentage, setPercentage] = useState(0);
    const [URL, setURL] = useState('');
    const [fName, setFName] = useState('');

    const handleFile = async (e) => {
      try {
        const file = e.target.files[0];
        setFName(e.target.files[0].name);
        const formData = new FormData();
        let res = null;
        formData.append("file", file);
        formData.append("upload_preset", "f1khspxl")
        formData.append("cloud_name", "cisanalytic")
        res = await uploadFile(formData, setPercentage);

        setPercentage(0);
       // console.log("res:" + res);
       // console.log(" res.status:" + res.status);
       // console.log("res.secure_url new:" + res.secure_url);
      //  console.log("public_id new:" + res.public_id);
        setURL(res.secure_url);
        setPublic_id(res.public_id);
        setImage(res.secure_url);
        setFileUploaded(true);
        if (res.status === "200") {
          let currentDateTime = Date().toLocaleString();
          //console.log("currentDateTime 1:" + currentDateTime);
     
            logImageDelete(imgPublic_Id, currentDateTime);
          
        }
      } catch (err) {
        setPercentage(0);
        console.log(err);
      }
      return URL;
    }
    return (
      <div>
        <p><b>Kapak Resmi</b></p>
        <label htmlFor="filePicker" style={{ background: "grey", padding: "5px 10px" }}>Yeni Kapak Resmi Seç</label>
        <input onChange={handleFile} id="filePicker" style={{ visibility: "hidden" }} type={"file"} />
        <p>{percentage} %</p>
        {/* {fileUploaded && <p>{fName}</p>} Conditional rendering */}
      </div>
    );
  }

  function MultiUploadWidget() {
    const [percentage, setPercentage] = useState(0);
    let URLs = "[";
    const handleFileMulti = async (e) => {
      try {
        let file = e.target.files[0];
        const formData = new FormData();
        let res = null;
        let tmpStr = "";

        formData.append("upload_preset", "f1khspxl")
        formData.append("cloud_name", "cisanalytic")
        let upperLimit = e.target.files.length;
        for (let i = 0; i < upperLimit; i++) {
          file = e.target.files[i];
          formData.append("file", file);
          res = await uploadFile(formData, setPercentage);
          setPercentage(0);
          //  URLs = URLs + "{\"url\":\"" + res.secure_url + "\"}";
          tmpStr = "{\"url\":\"{secure_url}\", \"public_id\":\"{public_id}\"}"
          URLs = URLs + tmpStr.replace("{secure_url}", res.secure_url).replace("{public_id}", res.public_id);

          if (!(i === 0 && upperLimit === 1)) {
            if (!(i > 0 && i === (upperLimit - 1))) {
              URLs = URLs + ",";
            }
          }
        }
        URLs = URLs + "]";
        picture_gallery_urls = JSON.parse(URLs);

      } catch (err) {
        setPercentage(0);
        console.log(err);
      }
      return URL;
    }
    return (
      <div className="checkList">
        <form className="uploader" encType="multipart/form-data">
          <label htmlFor="filePickerM" style={{ background: "grey", padding: "5px 10px" }}>Galeriye Resim Ekle</label>
          <input multiple onChange={handleFileMulti} id="filePickerM" style={{ visibility: "hidden" }} type={"file"} showCheckbox />
          <p>{percentage} %</p>
        </form>
      </div>
    );
  }



  const updVehicle = async (e) => {
    e.preventDefault();
    let active_changed = false;
    let int_mapped = (item?.interior_items ? item?.interior_items + ", " + int_checkedItems : int_checkedItems);
    int_mapped = int_mapped.replace(", , ", ", ").trim();
    const int_filtered = [...new Set(int_mapped.split(','))];

    if (int_filtered.length > 0) {
      if (int_filtered[int_filtered.length - 1] === "") {
        int_filtered.pop();
      }
      if (int_filtered[0] === ", ") {
        int_filtered.slice(1);
      }
    }

    item.interior_items = int_filtered.toString();

    let ext_mapped = (item?.exterior_items ? item?.exterior_items + ", " + ext_checkedItems : ext_checkedItems);
    ext_mapped = ext_mapped.replace(", , ", ", ").trim();
    const ext_filtered = [...new Set(ext_mapped.split(','))]
    if (ext_filtered.length > 0) {
      if (ext_filtered[ext_filtered.length - 1] === "") {
        ext_filtered.pop();
      }
      if (ext_filtered[0] === ", ") {
        ext_filtered.slice(1);
      }
    }
    item.exterior_items = ext_filtered.toString();

    let ent_mapped = (item?.entertainment_items ? item?.entertainment_items + ", " + ent_checkedItems : ent_checkedItems);
    ent_mapped = ent_mapped.replace(", , ", ", ").trim();
    const ent_filtered = [...new Set(ent_mapped.split(','))]
    if (ent_filtered.length > 0) {
      if (ent_filtered[ent_filtered.length - 1] === "") {
        ent_filtered.pop();
      }
      if (ent_filtered[0] === ", ") {
        ent_filtered.slice(1);
      }
    }
    item.entertainment_items = ent_filtered.toString();

    if (engine_volume !== "~|~") {
      item.engine_volume = engine_volume;
    }
    if (horse_power !== "~|~") {
      item.horse_power = horse_power;
    }
    if (mileage !== "~|~") {
      item.mileage = mileage;
    }
    if (price !== "~|~") {
      item.price = price;
    }
    if (sub_model !== "~|~") {
      item.sub_model = sub_model;
    }
    if (year !== "") {
      item.year = year;
    }

    if (is_active !== "" && is_active !== item?.is_active) {
      active_changed = true;
     // console.log("active_changed: " + active_changed);
    }

    if (is_active !== "") {
      item.is_active = is_active;
    }
    if (make !== "") {
      item.make = make;
    }

    if (model !== "") {
      item.model = model;
    }
    if (chassis !== "") {
      item.chassis = chassis;
    }
    if (location !== "") {
      item.location = location;
    }
    if (color !== "") {
      item.color = color;
    }
    if (fuel_type !== "") {
      item.fuel_type = fuel_type;
    }
    if (transmission !== "") {
      item.transmission = transmission;
    }
    if (steering_wheel !== "") {
      item.steering_wheel = steering_wheel;
    }

    // do the checked variable processing here to mark the gallery pictures to be deleted, and remove them from the 
    // picture_gallery_urls list.
    //
    let i, j = 0;
   // console.log("current list below: " + item?.picture_gallery_urls.length);
  /*  for (i = 0; i < item?.picture_gallery_urls.length; i++) {
      console.log("current list: " + item?.picture_gallery_urls[i].url)
    } */

    let tmpPicture_gallery_urls = [...item?.picture_gallery_urls, ...picture_gallery_urls];
  /*  console.log("current list copied below: " + tmpPicture_gallery_urls.length);
    for (i = 0; i < tmpPicture_gallery_urls.length; i++) {
      console.log("copied list: " + tmpPicture_gallery_urls[i].url)
    } */


    for (i = 0; i < tmpPicture_gallery_urls.length; i++) {
      for (j = 1; j < gChecked.length; j++) {
        if (i === gChecked[j]) {
          tmpPicture_gallery_urls[i].url = "";
          tmpPicture_gallery_urls[i].public_id = "";
        }
      }
    }
 /*   console.log("updated list below: " + tmpPicture_gallery_urls.length);
    for (i = 0; i < tmpPicture_gallery_urls.length; i++) {
      console.log("updated list: " + tmpPicture_gallery_urls[i].url)
    } */

    item.picture_gallery_urls = tmpPicture_gallery_urls.filter(tmpImg => tmpImg.url !== "");
   /* console.log("cleaned item list below: " + item.picture_gallery_urls.length);
    for (i = 0; i < item.picture_gallery_urls.length; i++) {
      console.log("cleaned item list: " + item.picture_gallery_urls[i].url)
    } */

    picture_gallery_urls = item?.picture_gallery_urls;
    /* console.log("cleaned list below: " + picture_gallery_urls.length);
    for (i = 0; i < picture_gallery_urls.length; i++) {
      console.log("cleaned list: " + picture_gallery_urls[i].url)
    } */

    const post = {
      client_id: client_id,
      vehicleId: item?.vehicleId,
      make: item?.make,
      year: item?.year,
      model: item?.model,
      chassis: item?.chassis,
      mileage: item?.mileage,
      sub_model: item?.sub_model,
      color: item?.color,
      is_active: item?.is_active,
      location: item?.location,
      price: item?.price,
      image: image,
      picture_gallery_urls: picture_gallery_urls,
      engine_volume: item?.engine_volume,
      fuel_type: item?.fuel_type,
      horse_power: item?.horse_power,
      transmission: item?.transmission,
      steering_wheel: item?.steering_wheel,
      interior_items: `${int_filtered}`,
      exterior_items: `${ext_filtered}`,
      entertainment_items: `${ent_filtered}`

    }
    // console.log(post);
    try {
      const res = await axios.post('https://data.mongodb-api.com/app/fsml-eeveh/endpoint/updateVehicle?arg1=' + item?.client_id + '&arg2=' + item?.vehicleId, post);
      // console.log("active_changed :" + active_changed);

      if (active_changed === true) {
        // console.log("active_changed so reloading:");
        window.location.reload(false);
      }

    } catch (e) {
      alert(e)
    }
    closeModal();
  }

  // State with list of all checked item
  const [int_checked, int_setChecked] = useState([]);
  const [ext_checked, ext_setChecked] = useState([]);
  const [ent_checked, ent_setChecked] = useState([]);
  // const [is_active, set_is_active] = useState('');
  // const [updated, setUpdated] = useState('');

  const int_handleCheck = (selectedList, selectedItem) => {
    // console.log('selectedItem.value:' + selectedItem.value);
    // console.log("item?.interior_items: " + item?.interior_items)
    var int_updatedList = [...int_checked];
    if (selectedItem.value) {
      int_updatedList = [...int_checked, selectedItem.value];
    } else {
      int_updatedList.splice(int_checked.indexOf(selectedItem.value), 1);
    }
    int_setChecked(int_updatedList);
  };
  const int_handleRemove = (selectedList, selectedItem) => {
    // console.log('selectedItem.value:' + selectedItem.value);
    //  console.log("item?.interior_items: " + item?.interior_items)
    var int_updatedList = [...int_checked];
    if (selectedItem.value) {
      if (item?.interior_items) {
        item.interior_items = item.interior_items.replace(selectedItem.value + ", ", "");
        item.interior_items = item.interior_items.replace(selectedItem.value, "");
        // console.log("item?.interior_items: " + item?.interior_items);
      }
    }
    int_setChecked(int_updatedList);
  };

  const ext_handleCheck = (selectedList, selectedItem) => {
    var ext_updatedList = [...ext_checked];
    if (selectedItem.value) {
      ext_updatedList = [...ext_checked, selectedItem.value];
    } else {
      ext_updatedList.splice(ext_checked.indexOf(selectedItem.value), 1);
    }
    ext_setChecked(ext_updatedList);
  };
  const ext_handleRemove = (selectedList, selectedItem) => {
    var ext_updatedList = [...ext_checked];
    if (selectedItem.value) {
      if (item?.exterior_items) {
        item.exterior_items = item.exterior_items.replace(selectedItem.value + ", ", "");
        item.exterior_items = item.exterior_items.replace(selectedItem.value, "");
        // console.log("item?.exterior_items: " + item?.exterior_items);
      }
    }
    ext_setChecked(ext_updatedList);
  };
  const ent_handleCheck = (selectedList, selectedItem) => {
    var ent_updatedList = [...ent_checked];
    if (selectedItem.value) {
      ent_updatedList = [...ent_checked, selectedItem.value];
    } else {
      ent_updatedList.splice(ent_checked.indexOf(selectedItem.value), 1);
    }
    ent_setChecked(ent_updatedList);
  };
  const ent_handleRemove = (selectedList, selectedItem) => {
    var ent_updatedList = [...ent_checked];
    if (selectedItem.value) {
      if (item?.entertainment_items) {
        item.entertainment_items = item.entertainment_items.replace(selectedItem.value + ", ", "");
        item.entertainment_items = item.entertainment_items.replace(selectedItem.value, "");
        //console.log("item?.entertainment_items: " + item?.entertainment_items);
      }
    }
    ent_setChecked(ent_updatedList);
  };

  // Generate string of checked items
  const int_checkedItems = int_checked.length
    ? int_checked.reduce((total, item) => {
      return total + ", " + item;
    })
    : "";

  const ext_checkedItems = ext_checked.length
    ? ext_checked.reduce((total, item) => {
      return total + ", " + item;
    })
    : "";

  const ent_checkedItems = ent_checked.length
    ? ent_checked.reduce((total, item) => {
      return total + ", " + item;
    })
    : "";

  function enableDelete() {
    setShowDel(true);
    // setIsOpen(true);
  }
  /* const [checked, setChecked] = React.useState([1]);

  const handleToggle = (zvalue : number ) => () => {
    const currentIndex = checked.indexOf(zvalue);
    const newChecked = [...checked];
 
    if (currentIndex === -1) {
      newChecked.push(zvalue);
    } else {
      newChecked.splice(currentIndex, 1);
    }
 
    setChecked(newChecked);
  };
  const labelId = `checkbox-list-secondary-label-${zvalue}`; * /

// Return classes based on whether item is checked
/* var int_isChecked = (item) =>
  int_checked.includes(item) ? "checked-item" : "not-checked-item";

var ext_isChecked = (item) =>
  ext_checked.includes(item) ? "checked-item" : "not-checked-item";

var ent_isChecked = (item) =>
  ent_checked.includes(item) ? "checked-item" : "not-checked-item"; */

  return (
    <div className="col-sm-3"> {/* each returned vehicle box gets wider if this classname is removed*/}
      <div></div>   {/* 'test 3' shows on top of each item box   customStyles*/}
      <div className="resultSet-rounded-corners"> {/*  className="card my-2"  card-img-top */}
        <div>
          <div>
            <button onClick={openModal}><img src={item?.image} className="card" style={{ width: "95%", objectFit: "fill"}} alt="" /></button>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles} /* Reduced the modal window size from screen wide to the specs. {customStyles} */
            // backgroundcolor= "#FF0000"
            contentLabel="Araç Ekleme">
            <div>
              <button variant="contained" style={{ float: 'right' }} className="float-right" onClick={closeModal}>X</button>
              <form name="vehicle" onSubmit={updVehicle}>
                <div>
                  <br></br>
                  <div className="row">
                    <tr bgcolor="white">
                      <h6><b> {item?.year} {item?.make} {item?.model} </b> (İlan#: {item?.vehicleId})</h6>
                    </tr>
                  </div>
                  <div className="row">
                    <table>
                      <tr bgcolor="gray">
                        {/* small picture below */}
                        <img style={{ padding: "1%", width: 300, height: 220 }} src={item?.image} alt="car" />
                        {/* main features */}
                        <td >

                          <div style={{ fontSize: 12 }}>
                            <div className="col" >  {/*  style={{width: "30%"}} */}
                              <div className="mb-3">   {/* Year */}
                                <label className="form-label" htmlFor="make" style={{ color: 'black' }}>
                                  <b>Marka:&nbsp;</b>
                                </label>
                                <select name="make" options={makerOptions} defaultValue={item?.make} onChange={event => setMaker(event.target.value)}>
                                  {makerOptions.map((option) => (
                                    <option value={option.value}>{option.label}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="mb-3">   {/* Year */}
                                <label className="form-label" htmlFor="year" style={{ color: 'black' }}>
                                  <b>Yılı:&nbsp;</b>
                                </label>
                                <select name="year" options={yearOptions} defaultValue={item?.year} onChange={event => setYear(event.target.value)}>
                                  {yearOptions.map((option) => (
                                    <option value={option.value}>{option.label}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="mb-3">   {/* chassis */}
                                <label className="form-label" htmlFor="chassis" style={{ color: 'black' }}>
                                  <b>Kasa Tipi:&nbsp;</b>
                                </label>
                                <select name="chassis" options={chassisOptionsx} defaultValue={item?.chassis} onChange={event => setChassis(event.target.value)}>
                                  {chassisOptionsx.map((option) => (
                                    <option value={option.value}>{option.label}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="mb-3">   {/* fuel type */}
                                <label className="form-label" htmlFor="fuel_type" style={{ color: 'black' }}>
                                  <b>Yakıt Türü:&nbsp;</b>
                                </label>
                                <select name="fuel_type" options={fuel_typeOptions} defaultValue={item?.fuel_type} onChange={event => setFuel_type(event.target.value)}>
                                  {fuel_typeOptions.map((option) => (
                                    <option value={option.value}>{option.label}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="mb-3">   {/* transmission */}
                                <label className="form-label" htmlFor="transmission" style={{ color: 'black' }}>
                                  <b>Vites Türü:&nbsp;</b>
                                </label>
                                <select name="transmission" options={transmissionOptions} defaultValue={item?.transmission} onChange={event => setTransmission(event.target.value)}>
                                  {transmissionOptions.map((option) => (
                                    <option value={option.value}>{option.label}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="mb-3">   {/* steering wheel */}
                                <label className="form-label" htmlFor="steering_wheel" style={{ color: 'black' }}>
                                  <b>Direksiyon Tipi:&nbsp;</b>
                                </label>
                                <select name="steering_wheel" options={steering_wheelOptions} defaultValue={item?.steering_wheel} onChange={event => setSteering_wheel(event.target.value)}>
                                  {steering_wheelOptions.map((option) => (
                                    <option value={option.value}>{option.label}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="mb-3">   {/* is_active */}
                                <label className="form-label" htmlFor="is_active" style={{ color: 'yellow' }}>
                                  <b>Aktif:&nbsp;</b>
                                </label>
                                <select name="is_active" options={activeOptions} defaultValue={item?.is_active} onChange={event => setIs_active(event.target.value)}>
                                  {activeOptions.map((option) => (
                                    <option value={option.value}>{option.label}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="mb-3">   {/* location */}
                                <label className="form-label" htmlFor="location" style={{ color: 'Yellow' }}>
                                  <b>Lokasyon:&nbsp;</b>
                                </label>
                                <select name="location" options={locationOptions} defaultValue={item?.location} onChange={event => setLocation(event.target.value)} required>
                                  {locationOptions.map((option) => (
                                    <option value={option.value}>{option.label}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div style={{ fontSize: 12 }}>
                            <div className="col">  {/*  style={{width: "30%"}} */}
                              <div className="mb-3">   {/* Model */}
                                <label className="form-label" htmlFor="model" style={{ color: 'black' }}>
                                  <b>Model:&nbsp;</b>
                                </label>
                                <select name="model" options={newModelsData[getIndex(item?.make, newModelsData, 'make')].modelList} defaultValue={item?.model} onChange={event => setModel(event.target.value)}>
                                  {newModelsData[getIndex(item?.make, newModelsData, 'make')].modelList.map((option) => (
                                    <option value={option.value}>{option.label}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="mb-3">
                                <label className="form-label" htmlFor="sub_model" style={{ color: 'black' }}>
                                  <b>Model Variantı:</b>&nbsp;</label>
                                <input type="text" name="sub_model" id="sub_model" defaultValue={item?.sub_model} onChange={event => setSub_model(event.target.value)} />
                              </div>
                              <div className="mb-3">
                                <label className="form-label" htmlFor="engine_volume" style={{ color: 'black' }}>
                                  <b>Motor Hacmi:</b>&nbsp;</label>
                                <input type="text" name="engine_volume" id="engine_volume" defaultValue={item?.engine_volume} onChange={event => setEngine_volume(event.target.value)} />
                              </div>
                              <div className="mb-3">
                                <label className="form-label" htmlFor="horse_power" style={{ color: 'black' }}>
                                  <b>Beygir Gücü:</b>&nbsp;</label>
                                <input type="text" name="horse_power" id="color" defaultValue={item?.horse_power} onChange={event => setHorse_power(event.target.value)} />
                              </div>
                              <div className="mb-3">
                                <label className="form-label" htmlFor="mileage" style={{ color: 'black' }}>
                                  <b>Kilometre:</b>&nbsp;</label>
                                <input type="text" name="mileage" id="mileage" defaultValue={item?.mileage} onChange={event => setMileage(event.target.value)} />
                              </div>
                              <div className="mb-3">   {/* color */}
                                <label className="form-label" htmlFor="color" style={{ color: 'black' }}>
                                  <b>Renk:&nbsp;</b>
                                </label>
                                <select name="color" options={colorOptionsx} defaultValue={item?.color} onChange={event => setColor(event.target.value)}>
                                  {colorOptionsx.map((option) => (
                                    <option value={option.value}>{option.label}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="mb-3">
                                <label className="form-label" htmlFor="price" style={{ color: 'black' }}>
                                  <b>Fiyatı (GBP):</b></label>
                                <input type="text" name="price" id="price" defaultValue={item?.price} onChange={event => setPrice(event.target.value)} />
                              </div>
                              <div className="mb-3">
                                <label className="form-label" htmlFor="dummy" style={{ color: 'black' }}>
                                  <b>&nbsp;</b></label>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <br></br>
                      <tr>
                      </tr>

                    </table>
                    <tr bgcolor="white">
                      <h8><b>Diğer Özellikler</b></h8>
                    </tr>
                    <tr>
                      <div className="row">
                        <tr bgcolor="white">
                          <td>
                            <div className="resultSet-rounded-corners1" style={{ fontSize: 13 }}>
                              <tr bgcolor="beige">
                                <h6><b>İç Donanım&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></h6>
                              </tr>
                              <div className="checkList">
                                <div className="col">
                                  <Multiselect
                                    options={int_accessoriesOptions} // Options to display in the dropdown
                                    selectedValues={int_accessoriesOptions.filter(
                                      i_option =>
                                        (item?.interior_items).includes(i_option?.value)
                                    )}
                                    // Preselected value to persist in dropdown
                                    onSelect={int_handleCheck} // Function will trigger on select event
                                    onRemove={int_handleRemove} // Function will trigger on remove event
                                    displayValue="label" // Property name to display in the dropdown options
                                    showCheckbox
                                  />
                                </div>
                              </div>
                              <br></br>
                            </div>
                          </td>
                          <td>
                            <div className="resultSet-rounded-corners1" style={{ fontSize: 13 }}>
                              <tr bgcolor="beige">
                                <h6><b>Dış Donanım&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></h6>

                              </tr>
                              <div className="checkList">
                                <div className="col">
                                  <Multiselect
                                    // defaultValue={"Sunroof"}
                                    options={ext_accessoriesOptions} // Options to display in the dropdown
                                    selectedValues={ext_accessoriesOptions.filter(
                                      i_option =>
                                        (item?.exterior_items).includes(i_option.value)
                                    )} // Preselected value to persist in dropdown
                                    onSelect={ext_handleCheck} // Function will trigger on select event
                                    onRemove={ext_handleRemove} // Function will trigger on remove event
                                    displayValue="label" // Property name to display in the dropdown options
                                    showCheckbox
                                  />
                                  {/*{ext_accessoriesOptions.map((item_Ext, index) => (
                                      <div key={index}>
                                        <input value={item_Ext} type="checkbox" onChange={ext_handleCheck} />
                                        {item_Ext}
                                      </div>
                                    ))} */}
                                </div>
                              </div>
                              <br></br>
                            </div>
                          </td>
                          <td>
                            <div className="resultSet-rounded-corners1" style={{ fontSize: 13 }}>
                              <tr bgcolor="beige">
                                <h6><b>Eğlence Sistemi&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></h6>
                              </tr>
                              <div className="checkList">
                                <div className="col">
                                  <Multiselect
                                    options={ent_accessoriesOptions} // Options to display in the dropdown
                                    selectedValues={ent_accessoriesOptions.filter(
                                      i_option =>
                                        (item?.entertainment_items).includes(i_option.value)
                                    )}// Preselected value to persist in dropdown
                                    onSelect={ent_handleCheck} // Function will trigger on select event
                                    onRemove={ent_handleRemove} // Function will trigger on remove event
                                    displayValue="label" // Property name to display in the dropdown options
                                    showCheckbox
                                  />
                                  {/*} {ent_accessoriesOptions.map((item_Ent, index) => (
                                      <div key={index}>
                                        <input value={item_Ent} type="checkbox" onChange={ent_handleCheck} />
                                        {item_Ent}
                                      </div>
                                   ))} */}
                                </div>
                              </div>
                              <br></br>
                            </div>
                          </td>
                          <td>
                            <div className="list-container">
                              <div>
                                {/* {console.log("item?.public_id 0" + item?.public_id)} */}
                                <UploadWidget imgPublic_Id={item?.public_id} />
                                <b>Galeri Resimleri</b><br></br>

                                <div className="resultSet-rounded-corners1">
                                  <div className="resultSet-rounded-corners1">
                                    {item?.picture_gallery_urls.map(galImg => <img src={galImg.url} style={{ width: 120, height: 100 }} alt="ssa" />)}
                                    <MultiUploadWidget />
                                  </div>
                                  <div>
                                    <p><b>Silinecek Resimleri Seçiniz</b></p>
                                    <CheckboxListSecondary urlList={[...item?.picture_gallery_urls, ...picture_gallery_urls]} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </div>
                      {/*{`preexisting interior_items: ${item?.interior_items}`}
                        {`year: ${year}`} */}
                    </tr>
                  </div>
                  <div className="float-end"  >
                    {/* <Button bsStyle="info" onClick="">Güncelle</Button> btn btn-secondary, btn btn-primary */}
                    <button type="submit" className="btn btn-danger" id="submit" style={{ color: "white" }} value="Submit"
                    ><b>Güncelle</b></button>
                  </div>
                  <div>&nbsp;</div>
                </div>
              </form>
            </div>
          </Modal>
        </div>
        <div> {/*  className="card-body" or  className="container-fluid homepage-bgimage"  */}
          <h6 className="card-title">
            {item?.make} {item?.model}
          </h6>
          {/* } <p className="card-text">{item?.year}  {item?.transmission} {item?.fuel_type}</p>   
          <p className="card-text">{item?.engine_volume} {item?.price} GBP {item?.color}</p> */}
          <div className="row">

            <div style={{ fontSize: 13 }} className="col">
              √{item?.year}
            </div>
            <div style={{ fontSize: 13 }} className="col">
              √{item?.transmission}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <b>{item?.location}</b>
            </div>
            <div className="col">
              <button onClick={delVehicle} className="btn btn-danger" style={{ display: 'flex', justifyContent: 'center', fontSize: 10, width: "25px", height: "22px" }}>Sil</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleItem;