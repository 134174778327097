import React from 'react'
import AdminFooter from '../Components/AdminFooter';
import OtoFabLogo from '../OtoFab_Logo.jpg';
import web_traffic from '../web_traffic.jpg';
import site_traffic from '../site_traffic.jpg';
import mobilePhoneIcon from '../mobilePhone.jpg';
import {addProductPageOpen, addCommunication} from '../Components/DB_DATA';

/* import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import BusinessIcon from '@mui/icons-material/Business'; */

const Product = (props) => {
  const client_id = 1;
  addProductPageOpen(client_id);

  function handlePhoneClick(e) {
    const client_id = "1"; 
    addCommunication(client_id, "mobile phone");       
    //console.log('The phone link was clicked.');
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-10">
          <br></br>
          <img src={OtoFabLogo} alt="example" style={{ width: 132, height: 76, float: "left" }} className="rounded-corners" />
          <h3 style={{ textAlign: "left" }}><br></br> &nbsp;&nbsp;&nbsp;&nbsp;Neden OtoFab?</h3>
        </div>
      
      </div>
      <div className="row">
        <p></p>
        <br></br>
        <br></br>
        <div className="col-md-8">
          <p>
            <b>● Daha fazla müşteriye ulaşıp araçlarınızı daha hızla satmak ister misiniz? </b><br></br>

            &nbsp;&nbsp;&nbsp;&nbsp;√ Kendi kurum adınızı taşıyan, size özel web sitesi<br></br>

            &nbsp;&nbsp;&nbsp;&nbsp;√ Kullanımı kolay, hızlı araç tarama ve görüntüleme<br></br>

            &nbsp;&nbsp;&nbsp;&nbsp;√ Web sitenizin ön plana çıkaran Google arama motoruna optimize ayarlar<br></br><br></br>
          </p>
        </div>
        <div className="col-md-4">
          <img src={web_traffic} alt="web_traffic" className="rounded-corners" /><br></br>
        </div>

        <div className="row">
          <div className="col-md-8">
            <b>● Müşterileriniz daha galeriye gelmeden hangi arabalara ilgi duyduklarını bilmek ister misiniz? </b><br></br>
            &nbsp;&nbsp;&nbsp;&nbsp;√ Web sitenizde hangi araçları en çok görüntülendiğini öğrenin<br></br>
            &nbsp;&nbsp;&nbsp;&nbsp;√ Düzenli raporlarla en çok ve en az ilgi gören araçları takip edin<br></br><br></br>
          </div>
          <div className="col-md-4">
            <img src={site_traffic} alt="site_traffic" className="rounded-corners" /><br></br>
          </div>
        </div>
        <div className="row">
          <b>● Düzenli raporlarla en çok ve en az ilgi gören araçları takip etmek isteyip</b> <br></br><br></br>
          <b>● Sosyal medya hesaplarınızdan pazarlama yeterli gelmiyorsa ve kendi sitenizi hızlıca kurabilmek istiyorsanız </b> <br></br>
          <div className="col-md-8"> <b>OtoFab</b> sistemi aradığınız çözümü sunacaktır:<br></br>
            &nbsp;&nbsp;&nbsp;&nbsp;√ Ekspres Paket <br></br>
            &nbsp;&nbsp;&nbsp;&nbsp;√ Standart Paket <br></br> <br></br>

            
          </div>
          <br></br><br></br>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ columnWidth: "90", backgroundColor:  "", color: "black", textAlign: "left", fontSize: 18 }}>
            <b><a style={{textDecoration: 'none' }} onClick={() => {handlePhoneClick()}} href="tel:0533 870 3350">
              <img src={mobilePhoneIcon} alt="mobilePhone" />&nbsp;Lansmana özel fiyatlar ve ayrıntılılar için bizi arayın, öne geçin!</a></b>
          </div>
        </div>
      </div>
      <AdminFooter />
    </div>
  )
}
export default Product