import React, { useEffect, useState } from "react";
import { FetchUserData } from "../Components/UserData";
import AdminFooter from '../Components/AdminFooter';
import ClientLogo_101 from '../logo_101.png';
import ClientLogo_100 from '../logo_100.png';

const Users = (props) => {

 const [reactData, setReactData] = useState([]);
 console.log('user login status: '+props.LoginStatus);
 const savedId = localStorage.getItem("client_id");
 let client_id = JSON.parse(savedId);

 var logoInUse = ClientLogo_100;
 if(client_id === "101"){
   logoInUse = ClientLogo_101;
 }
  React.useEffect(() => {
    FetchUserData(client_id).then(res => {
      setReactData(res.data);
    })
  }, []);

  return (
    <div style={{ backgroundColor: "lightgray", color: "black" }}>
      <img src={logoInUse} alt="example" style={{ float: "left" }} className="rounded-corners" />
      <div><h5 style={{ float: "center" }}>&nbsp;&nbsp;Kullanıcılar</h5></div>
      <br></br>
      <div className="container">
        <div className="row mt-6">
          <table style={{ "textAlign": "left", "borderWidth": "4px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
            <thead style={{ 'backgroundColor': 'beige', 'borderColor': "#aaaaaa", 'width': '300px' }}>
              <tr>
                <th>#</th>
                <th>Kullanıcı Adı</th>
                <th>İsim         </th>
                <th>Email        </th>
                <th>Mobil Tel No </th>
                <th>Lokasyon     </th>
              </tr>
            </thead>
            <tbody>
              {reactData.map((item, index) => (
                <tr key={item?.ID} style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                  <th scope="row" style={{ width: 30 }}>{index + 1}</th>
                  <td style={{ width: 110 }}>{item?.username}   </td>
                  <td style={{ width: 180 }}>{item?.first_name} {item?.last_name}  </td>
                  <td style={{ width: 240 }}>{item?.email}</td>
                  <td style={{ width: 130 }}>{item?.mobile_no}</td>
                  <td>{item?.location}</td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>
      <AdminFooter />
    </div>
  )
}

export default Users;