import React, { useState } from "react";
import { FetchDataInactive } from "../Components/DB_DATA";
//import { makerOptions } from "./Home";
import { FetchMakerOptions } from "../Components/DB_DATA";
//import { modelsData } from "../Components/MOCK_DATA";
import { FetchModelOptions } from "../Components/DB_DATA";
import { FetchChassisOptions } from "../Components/DB_DATA";
import { FetchColorOptions } from "../Components/DB_DATA";
import { yearOptions } from "./Home";
import { fuel_typeOptions } from "./Home";
import { locationOptions } from "./Home";
import { transmissionOptions } from "./Home";
import { steering_wheelOptions } from "./Home";
import { colorOptions } from "./Home";
import { int_accessoriesOptions } from "./Home";
import { ext_accessoriesOptions } from "./Home";
import { ent_accessoriesOptions } from "./Home";
import { activeOptions } from "./Home";
import uploadFile from "../Components/upload";
import axios from 'axios';
import ClientLogo_101 from '../logo_101.png';
import ClientLogo_100 from '../logo_100.png';
import Modal from 'react-modal';
import VehicleItem from "../Components/VehicleItem";
import FilterBar from "../Components/FilterBar";
import AdminFooter from '../Components/AdminFooter';
import "../Components/VehicleItem.css";
import '../bootstrap/dist/css/bootstrap.min.css'
import '../bootstrap/dist/css/bootstrap.css';
// import Button from 'react-bootstrap-buttons'; // react-bootstrap/Button
// import { MDBBtn } from 'mdb-react-ui-kit';
import styles from "../Components/styles.css";

export default function InactiveCars() {
  
  const [DBData, setDBData] = React.useState([]);
  const [makerOptions, setDBMakerOptions] = React.useState([]);
  const [newModelsDatax, setDBModelsData] = React.useState([]);
  const [chassisOptionsx, setChassisOptions] = React.useState([]);
  const [colorOptionsx, setColorOptions] = React.useState([]);
  let client_id = ""; /* ??? initialization which is actually used */
  const savedId = localStorage.getItem("client_id");

    client_id = JSON.parse(savedId);
    var logoInUse = ClientLogo_100;
    if(client_id === "101"){
      logoInUse = ClientLogo_101;
    }

  React.useEffect(() => {
    FetchDataInactive(client_id).then(res => {
      setDBData(eval(JSON.stringify(res.data, null, 2).replace(/"([^"]+)":/g, '$1:')))
    })
  }, []);

  React.useEffect(() => {
    FetchMakerOptions(client_id).then(res => {
      setDBMakerOptions(eval(JSON.stringify(res.data, null, 2).replace(/"([^"]+)":/g, '$1:')))
    })
  }, []);

  React.useEffect(() => {
    FetchModelOptions(client_id).then(res => {
      setDBModelsData(eval(JSON.stringify(res.data, null, 2).replace(/"([^"]+)":/g, '$1:')))
    })
  }, []); 
  React.useEffect(() => {
    FetchChassisOptions(client_id).then(res => {
      setChassisOptions(eval(JSON.stringify(res.data, null, 2).replace(/"([^"]+)":/g, '$1:')))
    })
  }, []);
  
  React.useEffect(() => {
    FetchColorOptions(client_id).then(res => {
      setColorOptions(eval(JSON.stringify(res.data, null, 2).replace(/"([^"]+)":/g, '$1:')))
    })
  }, []);
  
  var newModelsData =[{make: "", modelList: [{value: "", label:""}]}];
  //console.log("newModelsDatax length: "+newModelsDatax.length);
  if( newModelsDatax.length !== 0){
    newModelsData = newModelsDatax;
  }
 // console.log("makerOptions length: "+makerOptions.length);
let  vehicleList = (DBData);

  // This is where display data set assignment is done
  const [allData, setData] = useState(vehicleList);
  
  const addVehicle = async (e) => {
    e.preventDefault();
  //  console.log("picture_gallery_urls:" + picture_gallery_urls + ":");
    if (maker === "") {
      return "";
    }
    const valid = true;
    const vehicle = {
      client_id: client_id,
      make: maker,
      model: model,
      is_active: is_active,
      price: price,
      engine_volume: engine_volume,
      horse_power: horse_power,
      other_features: "",
      has_warranty: "",
      warranty: "",
      image: image,
      public_id: public_id,
      picture_gallery_urls: picture_gallery_urls,
      is_in_default_selection: "Yes",
      is_new_stock: "Yes",
      mileage: mileage,
      sub_model: sub_model,
      year: year,
      color: color,
      chassis: chassis,
      fuel_type: fuel_type,
      transmission: transmission,
      steering_wheel: steering_wheel,
      location: location,
      interior_items: int_checkedItems,
      exterior_items: ext_checkedItems,
      entertainment_items: ent_checkedItems
    }

    // Validations
    if (valid) {
      try {
        const res = await axios.post(`https://data.mongodb-api.com/app/fsml-eeveh/endpoint/addOneVehicle`, vehicle);       
        //setDBData(DBData.push(JSON.stringify(vehicle)));
       // vehicleList = DBData;
      } catch (e) {
        alert(e)
      }
      clearState();
      window.location.reload(false);
      closeModal();
      return "OK";
    }
  }
  // var jsonData = DBData;

  // State with list of all checked item
  const [maker, setMaker] = useState([]);
  const [selectedMaker, setSelectedMaker] = useState([]);
  const [model, setModel] = useState([]);
  const [int_checked, int_setChecked] = useState([]);
  const [ext_checked, ext_setChecked] = useState([]);
  const [ent_checked, ent_setChecked] = useState([]);
  const [is_active, setIs_active] = useState('');
  const [image, setImage] = useState('');
  const [public_id, setPublic_id] = useState('');
  const [picture_gallery_urls, setPicture_gallery_urls] = useState([]);
  const [year, setYear] = useState('');
  const [chassis, setChassis] = useState('');
  const [fuel_type, setFuel_type] = useState('');
  const [transmission, setTransmission] = useState('');
  const [steering_wheel, setSteering_wheel] = useState('');
  const [color, setColor] = useState('');
  const [price, setPrice] = React.useState('');
  const [horse_power, setHorse_power] = React.useState('');
  const [engine_volume, setEngine_volume] = React.useState('');
  const [mileage, setMileage] = React.useState('');
  const [sub_model, setSub_model] = React.useState('');
  const [location, setLocation] = React.useState('');

  const clearState = () => {
    setIs_active('');
  };
  const handleFilterTransmission = (transmission) => {
    const filteredData = vehicleList.filter((item) => {
      if (item?.transmission === transmission) {
        return item;
      }
    });
    setDBData(filteredData);
  };
  const handleFilterChassis = (chassis) => {
    const filteredData = vehicleList.filter((item) => {
      if (item?.chassis === chassis) {
        return item;
      }
    });
    setDBData(filteredData);
  };
  const handleFilterFuel_Type = (fuel_type) => {
    const filteredData = vehicleList.filter((item) => {
      if (item?.fuel_type === fuel_type) {
        return item;
      }
    });
    setDBData(filteredData);
  };
  const handleFilterSteering_Wheel = (steering_wheel) => {
    const filteredData = vehicleList.filter((item) => {
      if (item?.steering_wheel === steering_wheel) {
        return item;
      }
    });
    setDBData(filteredData);
  };

  const generateCarMakersDataForDropdown = () => {
   // console.log("vehicleList:"+vehicleList);
    return [...new Set(vehicleList.map((item) => item?.make))];
  };
  const generateChassisDataForDropdown = () => {
    return [...new Set(vehicleList.map((item) => item?.chassis))];
  };
  const generateFuel_TypeDataForDropdown = () => {
    return [...new Set(vehicleList.map((item) => item?.fuel_type))];
  };
  const generateSteering_WheelDataForDropdown = () => {
    return [...new Set(vehicleList.map((item) => item?.steering_wheel))];
  };
  const generateTransmissionDataForDropdown = () => {
    return [...new Set(vehicleList.map((item) => item?.transmission))];
  };

  const handleFilterMake = (make) => {
    const filteredData = vehicleList.filter((item) => {
      const fullName = `${item?.make}`;
      if (fullName?.toLowerCase().includes(make?.toLowerCase())) {
        return item;
      }
    });
    setDBData(filteredData);
  };

  const customStyles = {
    content: {
      top: '15%',
      height: '60%',
      width: '60%',
      left: '15%',
      right: '15%',
      bottom: '15%',
      marginRight: '2%',
      transform: 'translate(-5%, -5%)',
      backgroundcolor: "gray",
    },
    alignItems: "center",
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
    clearState();
    setSelectedMaker([]);
  }
  function afterOpenModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const int_handleCheck = (event) => {
    var int_updatedList = [...int_checked];
    if (event.target.checked) {
      int_updatedList = [...int_checked, event.target.value];
    } else {
      int_updatedList.splice(int_checked.indexOf(event.target.value), 1);
    }
    int_setChecked(int_updatedList);
  };

  const ext_handleCheck = (event) => {
    var ext_updatedList = [...ext_checked];
    if (event.target.checked) {
      ext_updatedList = [...ext_checked, event.target.value];
    } else {
      ext_updatedList.splice(ext_checked.indexOf(event.target.value), 1);
    }
    ext_setChecked(ext_updatedList);
  };

  const ent_handleCheck = (event) => {
    var ent_updatedList = [...ent_checked];
    if (event.target.checked) {
      ent_updatedList = [...ent_checked, event.target.value];
    } else {
      ent_updatedList.splice(ent_checked.indexOf(event.target.value), 1);
    }
    ent_setChecked(ent_updatedList);
  };
  // Generate string of checked items
  const int_checkedItems = int_checked.length
    ? int_checked.reduce((total, item) => {
      return total + ", " + item;
    })
    : "";

  const ext_checkedItems = ext_checked.length
    ? ext_checked.reduce((total, item) => {
      return total + ", " + item;
    })
    : "";

  const ent_checkedItems = ent_checked.length
    ? ent_checked.reduce((total, item) => {
      return total + ", " + item;
    })
    : "";

  function getIndex(value, arr, prop) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return 0; //to handle the case where the value doesn't exist
  }
  function UploadWidget() {
    const [percentage, setPercentage] = useState(0);
    const [URL, setURL] = useState('');
  
    //const [fName, setFName] = useState('');
    const handleFile = async (e) => {
      try {
        const file = e.target.files[0];
        //setFName(e.target.files[0].name);
        const formData = new FormData();
        let res = null;
        formData.append("file", file);
        formData.append("upload_preset", "f1khspxl")
        formData.append("cloud_name", "cisanalytic")
        res = await uploadFile(formData, setPercentage);
        setPercentage(0);
        console.log(res.secure_url);
        console.log("public_id:" + res.public_id);
        setURL(res.secure_url);
        setPublic_id(res.public_id);
        setImage(res.secure_url);
      } catch (err) {
        setPercentage(0);
        console.log(err);
      }
      return URL;
    }
    return (
      <div>
        <p><b>Kapak Resim</b></p>
        <input onChange={handleFile} type="file" />
        <p>{percentage} %</p>
      </div>
    );
  }
  function MultiUploadWidget() {
    const [mPercentage, setMPercentage] = useState(0);
    // const [URLs, setURLs] = useState('[{\"url\":');
    let URLs = "[";
    const handleFileMulti = async (e) => {
      try {
        let file = e.target.files[0];
        const formData = new FormData();
        let res = null;
        let tmpStr = "";

        formData.append("upload_preset", "f1khspxl")
        formData.append("cloud_name", "cisanalytic")
        let upperLimit = e.target.files.length;
        for (let i = 0; i < upperLimit; i++) {
          file = e.target.files[i];
          formData.append("file", file);
          res = await uploadFile(formData, setMPercentage);
          setMPercentage(0);
         // URLs = URLs + "{\"url\":\"" + res.secure_url + "\"}";
          tmpStr = "{\"url\":\"{secure_url}\", \"public_id\":\"{public_id}\"}"
          URLs = URLs + tmpStr.replace("{secure_url}", res.secure_url).replace("{public_id}", res.public_id);

          if (!(i === 0 && upperLimit === 1)) {
            if (!(i > 0 && i === (upperLimit - 1))) {
              URLs = URLs + ",";
            }
          }
        }
        URLs = URLs + "]";
        console.log("URLs: "+URLs);
        setPicture_gallery_urls(JSON.parse(URLs));
      //  console.log(JSON.stringify(URLs));

      } catch (err) {
        setMPercentage(0);
        console.log(err);
      }
      return URL;
    }
    return (
      <div>
        <p><b>Galeri Resimleri</b></p>
        <input onChange={handleFileMulti} type="file" multiple />
        <p>{mPercentage} %</p>
      </div>
    );
  }
  return (
    <div>
      <img src={logoInUse} alt="example" style={{ float: "left" }} className="rounded-corners" />
      <div style={{ backgroundColor: "lightgray", color: "gray" }}> <h5 style={{ float: "center" }}>&nbsp;&nbsp;Deaktif Araçlar</h5>
        <br></br>
        <td>
          <button onClick={openModal}>Yeni Araç</button>
        </td>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles} /* Reduced the modal window size from screen wide to the specs. {customStyles} */
          // backgroundcolor= "#FF0000"
          contentLabel="Yeni Araç">
          <div>
            <button variant="contained" style={{ float: 'right' }} className="float-right" onClick={closeModal}>X</button>
            <form name="vehicle" id="form1" onSubmit={addVehicle}>
              <tr>
                <td >
                  <tr bgcolor="white">
                    <h8><b>Ana Özellikler&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></h8>
                  </tr>
                  <div style={{ fontSize: 12 }}>
              
                    <div className="col" >  {/*  style={{width: "30%"}} */}
                      <div className="mb-3">   {/* Year */}
                        <label className="form-label" htmlFor="maker" style={{ color: 'red' }}>
                          <b>Marka:&nbsp;</b>
                        </label>
                        <select name="maker" options={makerOptions} defaultValue="----" onChange={event => { setMaker(event.target.value); setSelectedMaker(event.target.value) }} required>
                          {makerOptions.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="model" style={{ color: 'red' }}>
                          <b>Model:&nbsp; </b></label>
                        <select name="model" options={newModelsData[getIndex(selectedMaker, newModelsData, 'make')].modelList} defaultValue="" onChange={event => setModel(event.target.value)} required>
                          {newModelsData[getIndex(selectedMaker, newModelsData, 'make')].modelList.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3">   {/* Year */}
                        <label className="form-label" htmlFor="year" style={{ color: 'black' }}>
                          <b>Yılı:&nbsp;</b>
                        </label>
                        <select name="year" options={yearOptions} defaultValue="----" onChange={event => setYear(event.target.value)}>
                          {yearOptions.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3">   {/* chassis */}
                        <label className="form-label" htmlFor="chassis" style={{ color: 'black' }}>
                          <b>Kasa Tipi:&nbsp;</b>
                        </label>
                        <select name="chassis" options={chassisOptionsx} defaultValue="----" onChange={event => setChassis(event.target.value)}>
                          {chassisOptionsx.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3">   {/* fuel type */}
                        <label className="form-label" htmlFor="fuel_type" style={{ color: 'black' }}>
                          <b>Yakıt Türü:&nbsp;</b>
                        </label>
                        <select name="fuel_type" options={fuel_typeOptions} defaultValue="----" onChange={event => setFuel_type(event.target.value)}>
                          {fuel_typeOptions.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>

                      <div className="mb-3">   {/* transmission */}
                        <label className="form-label" htmlFor="transmission" style={{ color: 'black' }}>
                          <b>Vites Türü:&nbsp;</b>
                        </label>
                        <select name="transmission" options={transmissionOptions} defaultValue="----" onChange={event => setTransmission(event.target.value)}>
                          {transmissionOptions.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3">   {/* steering wheel */}
                        <label className="form-label" htmlFor="steering_wheel" style={{ color: 'black' }}>
                          <b>Direksiyon Tipi:&nbsp;</b>
                        </label>
                        <select name="steering_wheel" options={steering_wheelOptions} defaultValue="----" onChange={event => setSteering_wheel(event.target.value)}>
                          {steering_wheelOptions.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                      
                    </div>
                  </div>
                </td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td>
                  <tr bgcolor="white">
                    <h8><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></h8>
                  </tr>
                  <div style={{ fontSize: 12 }}>
                  
                  <div className="col">  {/*  style={{width: "30%"}} */}
                  <div className="mb-3">   {/* is_active */}
                      <label className="form-label" htmlFor="is_active" style={{ color: 'red' }}>
                        <b>Aktif:&nbsp;</b>
                      </label>
                      <select name="is_active" options={activeOptions} defaultValue="" onChange={event => setIs_active(event.target.value)} required>
                        {activeOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3">   {/* location */}
                      <label className="form-label" htmlFor="location" style={{ color: 'red' }}>
                        <b>Lokasyon:&nbsp;</b>
                      </label>
                      <select name="location" options={locationOptions} defaultValue="" onChange={event => setLocation(event.target.value)} required>
                        {locationOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="sub_model" style={{ color: 'black' }}>
                          <b>Model Variantı:</b>&nbsp;</label>
                        <input type="text" name="sub_model" id="sub_model" defaultValue="" onChange={event => setSub_model(event.target.value)} />
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="engine_volume" style={{ color: 'black' }}>
                          <b>Motor Hacmi:</b>&nbsp;</label>
                        <input type="text" name="engine_volume" id="engine_volume" defaultValue="" onChange={event => setEngine_volume(event.target.value)} />
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="horse_power" style={{ color: 'black' }}>
                          <b>Beygir Gücü:</b>&nbsp;</label>
                        <input type="text" name="horse_power" id="horse_power" defaultValue="" onChange={event => setHorse_power(event.target.value)} />
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="mileage" style={{ color: 'black' }}>
                          <b>Kilometre:</b>&nbsp;</label>
                        <input type="text" name="mileage" id="mileage" defaultValue="" onChange={event => setMileage(event.target.value)} />
                      </div>
                      <div className="mb-3">   {/* color */}
                        <label className="form-label" htmlFor="color" style={{ color: 'black' }}>
                          <b>Renk:&nbsp;</b>
                        </label>
                        <select name="color" options={colorOptionsx} defaultValue="----" onChange={event => setColor(event.target.value)} >
                          {colorOptionsx.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="price" style={{ color: 'black' }}>
                          <b>Fiyatı (GBP):</b></label>
                        <input type="text" name="price" id="price" defaultValue="0" onChange={event => setPrice(event.target.value)} />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr bgcolor="white">
                <h8><b>Diğer Özellikler</b></h8>
              </tr>

              <div className="row">
                <tr bgcolor="white">
                <td>
                    <div className="resultSet-rounded-corners1" style={{ fontSize: 13 }}>
                      <tr bgcolor="beige">
                        <h7><b>İç Donanım&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></h7>
                      </tr>
                      <div className="checkList">
                        <div className="list-container">
                          {int_accessoriesOptions.map((item, index) => (
                            <div key={index}>
                              <input value={item.value} type="checkbox" onChange={int_handleCheck} />
                              {item.label}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="resultSet-rounded-corners1" style={{ fontSize: 13 }}>
                      <tr bgcolor="beige">
                        <h7><b>Dış Donanım&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></h7>
                      </tr>
                      <div className="checkList">
                        <div className="list-container">
                          {ext_accessoriesOptions.map((item, index) => (
                            <div key={index}>
                              <input value={item.value} type="checkbox" onChange={ext_handleCheck} />
                              {item.label}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="resultSet-rounded-corners1" style={{ fontSize: 13 }}>
                      <tr bgcolor="beige">
                        <h7><b>Eğlence Sistemi&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></h7>
                      </tr>
                      <div className="checkList">
                        <div className="list-container">
                          {ent_accessoriesOptions.map((item, index) => (
                            <div key={index}>
                              <input value={item.value} type="checkbox" onChange={ent_handleCheck} />
                              {item.label}
                              
                            </div>
                          ))}
                          
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="list-container">
                      <div>
                        <UploadWidget />
               
                        <MultiUploadWidget />
                        
                      </div>
                      <br></br>
                     
                      {picture_gallery_urls.map(galImg => <img src={galImg.url} style={{ width: 120, height: 100 }} alt="ssa" />)}
                    </div>
                  </td>
                </tr>
              </div>
              {/* {`maker: ${maker}`} <br></br> {`model: ${model}`} <br></br>
              {`int Items checked are: ${int_checkedItems}`} <br></br>  */}
              <div className="float-end">
                <button type="submit" className="btn btn-danger" id="submit" style={{ color: "white" }} value="Submit">
                  <b>Ekle</b></button>
              </div>
              <div>&nbsp;</div>
            </form>
          </div>
        </Modal>
        <div className="container">
          <div className="row">
            <div className="col-sm-9">
              <FilterBar
                makers={generateCarMakersDataForDropdown()}
                chassises={generateChassisDataForDropdown()}
                fuel_types={generateFuel_TypeDataForDropdown()}
                steering_wheels={generateSteering_WheelDataForDropdown()}
                transmissions={generateTransmissionDataForDropdown()}
                onMakeFilter={handleFilterMake}
                onChassisFilter={handleFilterChassis}
                onFuel_TypeFilter={handleFilterFuel_Type}
                onSteering_WheelFilter={handleFilterSteering_Wheel}
                onTransmissionFilter={handleFilterTransmission}
              // onAfterOpen={handleFilterAll}
              />
              <div className="row mt-6">

                {
                  (DBData).map((item) => (
                    <VehicleItem item={item} key={item?.vehicleId}>
                    </VehicleItem>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div >
      <AdminFooter />
    </div>
  )
}

