import React, { useState } from "react";
// import { modelsData } from "./MOCK_DATA";
import '../bootstrap/dist/css/bootstrap.css';
// import { MDBBtn } from 'mdb-react-ui-kit';
function refreshPage  (event)  {
   window.location.reload(false);
}

const FilterBar = ({
  makers,
  chassises,
  fuel_types,
  steering_wheels,
  transmissions,
  onMakeFilter,
  onChassisFilter,
  onFuel_TypeFilter,
  onSteering_WheelFilter,
  onTransmissionFilter,
}) => {
  const [filters, setFilters] = useState({
    make: "",
    chassis: "",
    fuel_type: "",
    steering_wheel: "",
    transmission: "",
  });

  const [state, setState] = React.useState({
    transmission: "",
    make: ""
  })

  const handleInput = (field) => (event) => {
    const { value } = event.target;

    setFilters({
      ...filters,
      [field]: value,
    });

    switch (field) {
      case "make":
        onMakeFilter(value);
        break;
      case "chassis":
        onChassisFilter(value);
        break;
      case "fuel_type":
        onFuel_TypeFilter(value);
        break;
      case "steering_wheel":
        onSteering_WheelFilter(value);
        break;
      case "transmission":
        onTransmissionFilter(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="row main-lower-left-line"> 
      <div className="row my-5">
        <table>
          <tr>
            <td><div className="col-sm-12">
              <label htmlFor="maker">Marka</label>
              <select
                className="form-control"
                id="make"
                onChange={handleInput("make")}
              >
                <option value="">Seçiniz</option>
                {makers.map((make) => (
                  <option value={make} key={make}>
                    {make}
                  </option>
                ))}
              </select>
            </div> </td>
            <td><div className="col-sm-12">
              <label htmlFor="chassis">Kasa Tipi</label>
              <select
                className="form-control"
                id="chassis"
                onChange={handleInput("chassis")}
              >
                <option value="">Seçiniz</option>
                {chassises.map((chassis) => (
                  <option value={chassis} key={chassis}>
                    {chassis}
                  </option>
                ))}
              </select>
            </div></td>
            <td><div className="col-sm-12">
              <label htmlFor="fuel_type">Yakıt Türü</label>
              <select
                className="form-control"
                id="fuel_type"
                onChange={handleInput("fuel_type")}
              >
                <option value="">Seçiniz</option>
                {fuel_types.map((fuel_type) => (
                  <option value={fuel_type} key={fuel_type}>
                    {fuel_type}
                  </option>
                ))}
              </select>
            </div></td>
            <td><div className="col-sm-12">
              <label htmlFor="steering_wheel">Direksiyon Türü</label>
              <select
                className="form-control"
                id="steering_wheel"
                onChange={handleInput("steering_wheel")}
              >
                <option value="">Seçiniz</option>
                {steering_wheels.map((steering_wheel) => (
                  <option value={steering_wheel} key={steering_wheel}>
                    {steering_wheel}
                  </option>
                ))}
              </select>
            </div> </td>
            <td><div className="col-sm-12">
              <label htmlFor="transmission">Vites Türü</label>
              <select
                className="form-control"
                id="transmission"
                value={state.transmission}
                onChange={handleInput("transmission")}
              >
                <option value="">Seçiniz</option>
                {transmissions.map((transmission) => (
                  <option value={transmission} key={transmission}>
                    {transmission}
                  </option>
                ))}
              </select>
            </div></td>
            {/*<td>
              <button onClick={refreshPage}>Filtreleri temizle</button>            
                </td> */}
          </tr>
        </table>
      </div>
    </div>
  );
};

export default FilterBar;
