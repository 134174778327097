import axios from 'axios';

export const FetchUserData = async (clientId) => {
  let  client_id = clientId; /* ??? initialization which is actually used */
  console.log(client_id)
  const result = await axios(
    'https://data.mongodb-api.com/app/fsml-eeveh/endpoint/users?arg1=' + client_id,
  );
  return result; 
};
