import axios from "axios";

const uploadFile = async (body, setPercentage) => {
  try {
    
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setPercentage(percent);
        }
      }
    };

    const  resp  = await axios.post(
      "https://api.cloudinary.com/v1_1/cisanalytic/image/upload",
      body,
      options
    );
    console.log("resp.status: "+ resp.status);
    let refiResp = JSON.stringify(resp.data);
    console.log("refiResp data: "+ refiResp);
    let refiResp2 = JSON.stringify(resp.data.public_id);
    console.log("refiResp data.public_id: "+ refiResp2);

    return resp.data;
  } catch (error) {
    console.log(error.message);
  }
};

export default uploadFile;