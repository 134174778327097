import React from 'react'
import whatsappLogo from '../whatsapp.jpg';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
//import ContactMailIcon from '@mui/icons-material/ContactMail';
import emailIcon from '../email.png';
import mobilePhoneIcon from '../mobilePhone.jpg';
import {addCommunication} from '../Components/DB_DATA';
import ClientLogo_101 from '../logo_101.png';
import ClientLogo_100 from '../logo_100.png';
//import WhatsAppIcon from '@mui/icons-material/WhatsApp';
//import BusinessIcon from '@mui/icons-material/Business';

const AdminFooter = (props) => {
  const xx = props.xxx;
  const imageURL = "https://wa.me/905338703350";
  const OtoFab_URL = "/urun";
  const email = "mustafay@cisanalytic.com";

  function handleClick(type) {
    const client_id = "1"; 
    addCommunication(client_id, type);       
    //console.log('The phone link was clicked.');
  }

  return (
    <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
     {/* <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>

        <div>
          <a href='' className='me-5 text-reset'>
            <MDBIcon fab icon="twitter" />
          </a>
          <a href='' className='me-5 text-reset'>
            <MDBIcon fab icon="whatsapp" />
          </a>
  </div> 
      </section> */}
      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon icon="gem" className="me-4" />
                CIS Analytic
              </h6>
              <p>CIS Analytic ihtisas yazılım konularında hızlı ve etkin çözümler için hizmetinizdedir. Çözümler ve fiyat bilgileri için bize ulaşın.
              </p>
            </MDBCol>
            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-propercase fw-bold mb-4'>Ürünler</h6>
              <p>
                <a href={OtoFab_URL} className='text-reset'>OtoFab</a>
              </p>
              <p>
                <a href='#!' className='text-reset'>Fixit (Yakında)</a>
              </p>
            </MDBCol>
            <MDBCol md="2" lg="3" xl="3" className='mx-auto mb-md-0 mb-2'>
              <h6 className='text-propercase fw-bold mb-4'>İletişim</h6>
              <p>
                <a href="tel:0533 870 3350">
                  <img src={mobilePhoneIcon} width="40" height="33"  onClick={() => {handleClick("mobile phone")}} alt="mobilePhone" className="rounded-corners" />
                </a>&nbsp;&nbsp;
                <a href={"mailto:" + email + "?subject=OtoFab Ürünü Hakkında&body=Sayın Yetkili, "+"\n"} onClick={() => {handleClick("email")}}    target="_blank" rel="noreferrer">
                  <img src={emailIcon} alt="example" width="41  " height="33" className="rounded-corners" />
                </a> &nbsp;&nbsp;
                <a href={imageURL} onClick={() => {handleClick("whatsapp")}} target="_blank" rel="noreferrer">
                  <img src={whatsappLogo} width="39" height="33" alt="Whatsapp" className="rounded-corners" />
                </a>
              </p>
            </MDBCol>
            <MDBCol md="2" lg="3" xl="3" className='mx-auto mb-md-0 mb-2'>
              <h6 className='text-propercase fw-bold mb-4'>Bizi Tercih Edenler</h6>
              <p>
                <a href="https://firatserhatmotors.com" title="Fırat Serhat Motors LTD, Mağusa" target="_blank"> 
                  <img src={ClientLogo_101}  alt="FSML"  className="rounded" />
                </a>&nbsp;&nbsp;  .  &nbsp;&nbsp;        
             
                <a href="https://ornek-motors-mustafay-cisanalytic.vercel.app" title="Örnek Motors, KKTC" target="_blank"> 
                  <img src={ClientLogo_100}  alt="OtoFab Ürün"  width="53" height="36"  className="rounded" />
                </a>&nbsp;&nbsp;           
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </MDBFooter>
  )
}
export default AdminFooter